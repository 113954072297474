<template>
  <section>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />
    <v-form ref="form" v-model="isValid" @submit.prevent="handleSubmit">
      <base-text-field
        autocapitalize
        required
        v-model="formData.first_name"
        :autofocus="!fromRoute"
        :label="$t('name')"
        :placeholder="$t('placeholderName')"
        :rules="rulesGenerics"
      />
      <base-text-field
        autocapitalize
        required
        v-model="formData.last_name"
        :label="$t('lastName')"
        :placeholder="$t('placeholderLastName')"
        :rules="rulesGenerics"
      />
      <base-text-field
        required
        type="email"
        v-model="formData.email"
        :label="$t('email')"
        :placeholder="$t('placeholderEmail')"
        :rules="rulesEmail"
      />
      <base-text-field
        type="tel"
        v-model="formData.phone"
        :autofocus="!!fromRoute"
        :label="$t('phone')"
        :placeholder="$t('placeholderPhone')"
        :rules="rulesPhone"
      />
      <footer class="d-flex justify-center">
        <base-btn
          :color="!theme.isDark ? 'secondary' : 'white'"
          type="submit"
          class="my-2 my-md-0"
          :disabled="!isValid"
          block
        >
          {{ $t("save") }}
        </base-btn>
      </footer>
    </v-form>
  </section>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import { userCtrl } from "@/controllers";
import {
  getErrorPayloadForSnackbar,
  rulesGenerics,
  rulesEmail,
  rulesPhone,
} from "@/helpers";
import { useSnackbar } from "@/utils";
import { userInfoStoreActions } from "@/store/modules/userInfo/constNames";

export default {
  name: "UserSettingsForm",
  inject: ["theme"],
  props: {
    subtitle: String,
    title: {
      type: String,
      default: i18n.t("userSettings"),
    },
    userInfo: {
      Type: Object,
      required: true,
    },
  },
  mounted() {
    const paramFromRoute = this.$route.params.fromRoute;
    if (paramFromRoute) this.fromRoute = { ...paramFromRoute };
  },
  data() {
    return {
      fromRoute: false,
      formData: this.userInfo,
      isValid: false,
      rulesGenerics,
      rulesPhone,
      rulesEmail,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const isValid = await this.$refs.form.validate();
        if (!isValid) return;
        const { data, message } = await userCtrl.handleSaveSettings({
          ...this.formData,
        });
        if (data) {
          this.$store.dispatch(userInfoStoreActions.SAVE_USER, {
            ...this.formData,
          });
          const snackbar = useSnackbar();
          snackbar.dispatchOpenSnackbar(message);
          if (this.fromRoute.name)
            this.$router.push({ name: this.fromRoute.name });
        }
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      }
    },
  },
};
</script>
